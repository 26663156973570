import { LoginInput } from '@bluepic/types/src/Auth/login.input';
import { OperationOutput } from '@bluepic/types/src/Auth/operation.output';
import { UserUpdate } from '@bluepic/types/src/Auth/user.update';
import { InitTOTPOutput } from '@bluepic/types/src/Auth/initTOTP.output';
import { MagicLinkInput } from '@bluepic/types/src/Auth/magicLink.input';
import { UserOutput } from '@bluepic/types/src/Auth/user.output';
import { BaseController } from './baseController';
import { SessionOutput } from '@bluepic/types/src/Auth/session.output';
import { ApiKeyInput } from '@bluepic/types/src/Auth/apiKey.input';
import { ApiKeyOutput } from '@bluepic/types/src/Auth/apiKey.output';
import { AffiliateProfileInput } from '@bluepic/types/src/Auth/affiliateProfile.input';
import { AffiliateProfileOutput } from '@bluepic/types/src/Auth/affiliateProfile.output';
import { AffiliateKeyOutput } from '@bluepic/types/src/Auth/affiliateKey.output';
import { TeamInput } from '@bluepic/types/src/Auth/team.input';
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { TeamUpdate } from '@bluepic/types/src/Auth/team.update';
import { InviteInput } from '@bluepic/types/src/Auth/invite.input';
import { InviteOutput } from '@bluepic/types/src/Auth/invite.output';
import { MetaDataOutput } from '@bluepic/types/src/Auth/metaData.output';
import { MetaDataInput } from '@bluepic/types/src/Auth/metaData.input';
import { CustomPriceOutput } from '@bluepic/types/src/Auth/customPrice.output';
import { CustomPriceInput } from '@bluepic/types/src/Auth/customPrice.input';
import { GetAllUsersInputDto } from '@bluepic/types/src/Auth/getAllUsersInput.dto';
import { GetAllTeamsInputDto } from '@bluepic/types/src/Auth/getAllTeamsInput.dto';
import { PaginatedResponseDto } from '@bluepic/types/src/Auth/paginatedResponse.dto';
import { TeamSubscriptionOutput } from '@bluepic/types/src/Auth/teamSubscription.output';
import { TeamSubscriptionInput } from '@bluepic/types/src/Auth/teamSubscription.input';
import Stripe from 'stripe';
import { UserInput } from '@bluepic/types/src/Auth/user.input';
import { AgencyConnectionInput } from '@bluepic/types/src/Auth/agencyConnection.input';
import { AgencyConnectionOutput } from '@bluepic/types/src/Auth/agencyConnection.output';
import { AgencyTransactionOutput } from '@bluepic/types/src/Auth/agencyTransaction.output';
import { AgencyPayoutOutput } from '@bluepic/types/src/Auth/agencyPayout.output';
import { AgencyTransactionInput } from '@bluepic/types/src/Auth/agencyTransaction.input';

const baseUrl = import.meta.env.V_AUTH_BASE_URL;

const c = new BaseController(baseUrl);

export async function login(loginInput: LoginInput) {
  const url = c.getUrl('auth', 'login');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginInput),
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.text();
}

export async function refreshCredentials(jwt?: string, apikey?: string) {
  const url = c.getUrl('auth', 'refresh');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Refresh failed');
  }

  return await response.text();
}

export async function getMagicLink(data: MagicLinkInput) {
  const url = c.getUrl('auth', 'magiclink');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  // hier wurde bisher kein Check gemacht, also beibehalten
  return await response.text();
}

export async function redeemMagicLink(token: string) {
  const url = c.getUrl('auth', 'magiclink', 'redeem', token);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Redeem magic link failed');
  }

  return await response.text();
}

export async function createUser(registerInput: UserInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'create');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(registerInput),
  });

  if (!response.ok) {
    throw new Error('Create user failed');
  }
}

export async function createDummyUser(registerInput: UserInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'create', 'dummy');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(registerInput),
  });

  if (!response.ok) {
    throw new Error('Create user failed');
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return (await response.json()) as UserOutput;
  } else {
    return (await response.text()) as string;
  }
}

export async function confirmEmail(token: string) {
  const url = c.getUrl('emailconfirmation', token);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Confirm email failed');
  }
}

export async function resendEmails(userId: string) {
  const url = c.getUrl('user', 'resendemails', userId);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Resend emails failed');
  }
}

export async function getUser(jwt?: string, apikey?: string) {
  const url = c.getUrl('user');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get user failed');
  }

  const result = await response.json();
  return result[0] as UserOutput;
}

export async function getUsers(userIds: string[], jwt?: string, apikey?: string) {
  const url = c.getUrl('user?' + userIds.map((id) => `userid=${id}`).join('&'));

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get users failed');
  }

  return (await response.json()) as UserOutput[];
}

// POST /user/all
export async function getAllUsers(options: GetAllUsersInputDto, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'all');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(options),
  });

  if (!response.ok) {
    throw new Error('Get all users failed');
  }

  return (await response.json()) as PaginatedResponseDto<UserOutput>;
}

// POST /team/all
export async function getAllTeams(options: GetAllTeamsInputDto & { agency?: boolean }, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'all');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(options),
  });

  if (!response.ok) {
    throw new Error('Get all teams failed');
  }

  return (await response.json()) as PaginatedResponseDto<TeamOutput>;
}

export async function updateUser(
  userId: string,
  data: Pick<UserUpdate, 'name' | 'email' | 'password' | 'teamId' | 'hiddenTemplates' | 'onboardingProgress' | 'locale'>,
  jwt?: string,
  apikey?: string
) {
  const url = c.getUrl('user', 'update', userId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Update user failed');
  }

  return (await response.json()) as OperationOutput;
}

export async function deleteUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', userId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Delete user failed');
  }

  return (await response.json()) as OperationOutput;
}

export async function initmfa(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'initmfa', 'totp');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Init MFA failed');
  }

  return (await response.json()) as InitTOTPOutput;
}

export async function confirmtotp(code: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'verifytotp', code);

  const response = await fetch(url, {
    method: 'POST',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Confirm TOTP failed');
  }

  return (await response.json()) as string;
}

export async function verifytotp(userid: string, code: string, token: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('auth', 'totp', userid, token, code);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Verify TOTP failed');
  }

  return await response.text();
}

export async function getSessions(userid?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', userid ?? '');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get sessions failed');
  }

  return (await response.json()) as SessionOutput[];
}

export async function killSession(sessionId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', sessionId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Kill session failed');
  }

  return (await response.json()) as OperationOutput;
}

export async function getApiKeys(userId?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', userId ?? '');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get API keys failed');
  }

  return (await response.json()) as ApiKeyOutput[];
}

export async function createApiKey(data: ApiKeyInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', 'create');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create API key failed');
  }

  return (await response.json()) as OperationOutput;
}

export async function removeApiKey(apikeyId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('apikey', apikeyId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Remove API key failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH /user/lock/:userId
export async function lockUser(userId: string, reason: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'lock', userId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify({ reason }),
  });

  if (!response.ok) {
    throw new Error('Lock user failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH /user/unlock/:userId
export async function unlockUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'unlock', userId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Unlock user failed');
  }

  return (await response.json()) as OperationOutput;
}

// PUT /affiliate/profile
export async function createOrUpdateAffiliateProfile(data: AffiliateProfileInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'profile');

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create or update affiliate profile failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /affiliate/profile/{id}
export async function getAffiliateProfile(id?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'profile', id);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get affiliate profile failed');
  }

  return (await response.json()) as AffiliateProfileOutput;
}

// GET /affiliate/keys/{profileid}
export async function getAffiliateKeys(profileId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'keys', profileId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get affiliate keys failed');
  }

  return (await response.json()) as AffiliateKeyOutput[];
}

export interface AffiliateInvite {
  name?: string;
  organization?: string;
  email: string;
}

// POST /affiliate/invite/{profileid}
export async function inviteAffiliate(profileId: string, invites: AffiliateInvite[], jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'invite', profileId);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(invites),
  });

  if (!response.ok) {
    throw new Error('Invite affiliate failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE /affiliate/invite/{keyId}
export async function revokeAffiliateInvite(keyId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('affiliate', 'invite', keyId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Revoke affiliate invite failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /user/byaffiliate
export async function getUsersByAffiliate(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'byaffiliate');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get users by affiliate failed');
  }

  return (await response.json()) as UserOutput[];
}

// GET /affiliate/key/{key}
export async function getAffiliateKey(key: string) {
  const url = c.getUrl('affiliate', 'key', key);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Get affiliate key failed');
  }

  return (await response.json()) as AffiliateKeyOutput;
}

// GET /affiliate/templates
export async function getAffiliateTemplates() {
  const url = c.getUrl('affiliate', 'templates');

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Get affiliate templates failed');
  }

  return (await response.json()) as string[];
}

// GET /permission/current/{userid}
export async function getCurrentPermissions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'current', userId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get current permissions failed');
  }

  return (await response.json()) as string[];
}

// GET /permission/all/{userid}
export async function getAllPermissions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'all', userId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get all permissions failed');
  }

  return (await response.json()) as string[];
}

// GET /permission/team/{teamid}
export async function getTeamPermissions(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('permission', 'team', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get team permissions failed');
  }

  return (await response.json()) as string[];
}

// POST /team/create
export async function createTeam(data: TeamInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'create');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create team failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /team/byuser/{id}
export async function getTeamsOfUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'byuser', userId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get teams of user failed');
  }

  return (await response.json()) as TeamOutput[];
}

// GET /team
interface GetTeamOptions {
  teamIds?: string[];
  recursive?: boolean;
  jwt?: string;
  apikey?: string;
  success?: (teams: TeamOutput[]) => void;
  error?: (err: any) => void;
  finally?: () => void;
}
export function getTeams(options: GetTeamOptions): () => void {
  const query = {
    teamid: options.teamIds,
    recursive: options.recursive,
  };

  // Baue die URL mit den Query-Parametern
  const queryString = query.teamid || query.recursive ? `?${c.query(query)}` : '';
  const url = c.getUrl('team' + queryString);

  const controller = new AbortController();
  const { signal } = controller;

  // Setze die Header
  const headers = c.authHeader(options.jwt, options.apikey);

  // Führe die Fetch-Anfrage aus
  fetch(url, {
    method: 'GET',
    headers,
    signal,
  })
    .then(async (response) => {
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }
      return response.json() as Promise<TeamOutput[]>;
    })
    .then((data: TeamOutput[]) => {
      options.success?.(data);
    })
    .catch((error: any) => {
      // Überprüfe, ob der Fehler durch das Abbrechen der Anfrage verursacht wurde
      if (signal.aborted) {
        console.warn('Fetch request was aborted');
        return;
      }
      options.error?.(error);
    })
    .finally(() => {
      if (!signal.aborted) {
        options.finally?.();
      }
    });

  // Rückgabe der Abbruchfunktion
  return controller.abort.bind(controller);
}

// PATCH /team/update/{id}
export async function updateTeam(id: string, data: TeamUpdate, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'update', id);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Update team failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH /team/promote/{id}/{memberid}
export async function promoteTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'promote', id, memberId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Promote team member failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH /team/demote/{id}/{memberid}
export async function demoteTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'demote', id, memberId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Demote team member failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH /team/removemember/{id}/{memberid}
export async function removeTeamMember(id: string, memberId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'removemember', id, memberId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Remove team member failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE /team/{id}
export async function deleteTeam(id: string, hard?: boolean, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', id) + (hard ? '?hard=true' : '');

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Delete team failed');
  }

  return (await response.json()) as OperationOutput;
}

// POST /invite
export async function inviteUser(data: InviteInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...c.authHeader(jwt, apikey),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Invite user failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /invite/redeem/{id}
export async function redeemInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'redeem', id);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Redeem invite failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /invite/byUser/{userId}
export async function getInvitesByUser(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'byUser', userId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get invites by user failed');
  }

  return (await response.json()) as InviteOutput[];
}

// GET /invite/byTeam/{teamId}
export async function getInvitesByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'byTeam', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get invites by team failed');
  }

  return (await response.json()) as InviteOutput[];
}

// DELETE /invite/revoke/{id}
export async function revokeInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'revoke', id);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Revoke invite failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE /invite/decline/{id}
export async function declineInvite(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('invite', 'decline', id);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Decline invite failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET /invite/:id
export async function getInvite(id: string) {
  const url = c.getUrl('invite', id);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Get invite failed');
  }

  return (await response.json()) as InviteOutput;
}

// GET /metadata/:key
export async function getMetadata(
  options: {
    key?: string;
    userId?: string;
    teamId?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { key, userId, teamId } = options;
  let url = c.getUrl('metadata');
  if (key) {
    url += `/${key}`;
  }
  if (userId || teamId) {
    url += '?' + c.query({ userId, teamId });
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get metadata failed');
  }

  return (await response.json()) as MetaDataOutput | MetaDataOutput[];
}

// PUT /metadata/:key
export async function setMetadata(
  options: {
    key: string;
    userId?: string;
    teamId?: string;
    value?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { key, userId, teamId, value } = options;
  let url = c.getUrl('metadata', key);
  if (userId || teamId) {
    url += '?' + c.query({ userId, teamId });
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify({ value }),
  });

  if (!response.ok) {
    throw new Error('Set metadata failed');
  }

  return (await response.json()) as OperationOutput;
}

export type OIDCProvider = 'google' | 'apple' | 'facebook' | 'twitter';

export interface OIDCIdToken {
  provider: OIDCProvider;
  userID: string;
  email: string;
  name?: string;
  picture?: string;
}

// GET /oidc/verify?id_token={id_token}
export async function verifyIDToken(id_token: string) {
  const url = c.getUrl('oidc', 'verify') + '?' + c.query({ id_token });

  const response = await fetch(url, { method: 'GET' });

  if (!response.ok) {
    throw new Error('Verify ID token failed');
  }

  return (await response.json()) as OIDCIdToken;
}

// POST /user/addoidc
export async function addOIDC(id_token: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'addoidc');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify({ id_token }),
  });

  if (!response.ok) {
    throw new Error('Add OIDC failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE /user/removeoidc?id={id}
export async function removeOIDC(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'removeoidc') + '?' + c.query({ id });

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Remove OIDC failed');
  }

  return (await response.json()) as OperationOutput;
}

// POST /auth/login/oidc?affiliateKey={affiliateKey}&inviteId={inviteId}
export async function loginOIDC(id_token: string, affiliateKey?: string, inviteId?: string) {
  let url = c.getUrl('auth', 'login', 'oidc');
  const query = { affiliateKey, inviteId };
  if (query) {
    url += '?' + c.query(query);
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token }),
  });

  if (!response.ok) {
    throw new Error('Login OIDC failed');
  }

  return await response.text();
}

// GET /user/pictureuploadurl
export async function getPictureUploadUrl(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'pictureuploadurl');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get picture upload URL failed');
  }

  return (await response.text()) as string;
}

// GET /user/checkpictureupload
export async function checkPictureUpload(jwt?: string, apikey?: string) {
  const url = c.getUrl('user', 'checkpictureupload');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Check picture upload failed');
  }

  return (await response.json()) as boolean;
}

// GET /team/pictureuploadurl/:id
export async function getTeamPictureUploadUrl(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'pictureuploadurl', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get team picture upload URL failed');
  }

  return (await response.text()) as string;
}

// GET /team/checkpictureupload/:id
export async function checkTeamPictureUpload(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'checkpictureupload', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Check team picture upload failed');
  }

  return (await response.json()) as boolean;
}

// GET /billing/custom-price/all
export async function getAllCustomPrices(jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', 'all');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get all custom prices failed');
  }

  return (await response.json()) as CustomPriceOutput[];
}

// GET /billing/custom-price/:id
export async function getCustomPrice(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get custom price failed');
  }

  return (await response.json()) as CustomPriceOutput;
}

// GET billing/custom-price/by-team/:teamId
export async function getCustomPricesByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', 'by-team', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get custom prices by team failed');
  }

  return (await response.json()) as CustomPriceOutput[];
}

// PUT billing/custom-price/:id
export async function createOrUpdateCustomPrice(data: CustomPriceInput, id?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create or update custom price failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE billing/custom-price/:id
export async function deleteCustomPrice(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'custom-price', id);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Delete custom price failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET billing/team-subscription/by-team/:teamId
export async function getTeamSubscriptionByTeam(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'by-team', teamId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get team subscription by team failed');
  }

  const res = (await response.json()) as TeamSubscriptionOutput;
  res.slots = Number(res.slots);
  return res;
}

// GET billing/team-subscription/by-custom-price/{id}
export async function getTeamSubscriptionsByCustomPriceId(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'by-custom-price', id);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get team subscriptions by custom price failed');
  }

  const items = (await response.json()) as TeamSubscriptionOutput[];
  return items.map((subscription) => ({
    ...subscription,
    slots: Number(subscription.slots),
  }));
}

// PUT billing/team-subscription?successUrl={successUrl}&cancelUrl={cancelUrl}
export async function createOrUpdateTeamSubscription(
  data: TeamSubscriptionInput,
  successUrl?: string,
  cancelUrl?: string,
  jwt?: string,
  apikey?: string
) {
  let url = c.getUrl('billing', 'team-subscription');
  const query = { successUrl, cancelUrl };
  if (query) {
    url += '?' + c.query(query);
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create or update team subscription failed');
  }

  return await response.text();
}

// GET billing/team-subscription/checkout-success/:checkoutId
export async function teamSubscriptionCheckoutSuccess(checkoutId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('billing', 'team-subscription', 'checkout-success', checkoutId);

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Team subscription checkout success failed');
  }

  const res = (await response.json()) as TeamSubscriptionOutput;
  res.slots = Number(res.slots);
  return res;
}

// GET billing/team-subscription/cancellation-session/:id?returnUrl={returnUrl}
export async function getTeamSubscriptionCancellationSession(id: string, returnUrl?: string, jwt?: string, apikey?: string) {
  let url = c.getUrl('billing', 'team-subscription', 'cancellation-session', id);
  const query = { returnUrl };
  if (query) {
    url += '?' + c.query(query);
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get team subscription cancellation session failed');
  }

  return await response.text();
}

// GET auth/shared/:id
export async function sharedLogin(id: string) {
  const url = c.getUrl('auth', 'shared', id);

  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Shared login failed');
  }

  return await response.text();
}

// POST session/dummy/{userid}
export async function createDummySession(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'dummy', userId);

  const response = await fetch(url, {
    method: 'POST',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Create dummy session failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET session/ping
export async function pingSession(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'ping');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Ping session failed');
  }

  return await response.text();
}

// DELETE session/all/{userId}
export async function deleteAllSessions(userId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'all', userId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Delete all sessions failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET session/stats/ua
export async function getUAStats(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'stats', 'ua');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get UA stats failed');
  }

  // Falls hier kein JSON zurückkommt, anpassen.
  return await response.json();
}

// GET session/stats/usage
export async function getUsageStats(jwt?: string, apikey?: string) {
  const url = c.getUrl('session', 'stats', 'usage');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get usage stats failed');
  }

  // Falls hier kein JSON zurückkommt, anpassen.
  return await response.json();
}

// PATCH team/ownershiptransfer/:teamId/:targetId
export async function initTeamOwnershipTransfer(teamId: string, targetId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'ownershiptransfer', teamId, targetId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Init team ownership transfer failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE team/ownershiptransfer/:teamId
export async function cancelTeamOwnershipTransfer(teamId: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('team', 'ownershiptransfer', teamId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Cancel team ownership transfer failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH team/acceptownership/:teamId
export async function acceptTeamOwnershipTransfer(teamId: string, jwt?: string, apikey?: string) {
  console.log('acceptTeamOwnershipTransfer');
  const url = c.getUrl('team', 'acceptownership', teamId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Accept team ownership transfer failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET agency/connection?agencySpaceId={agencySpaceId}&targetSpaceId={targetSpaceId}&id={id1}&id={id2}
export async function getAgencyConnections(
  options: {
    agencySpaceId?: string;
    targetSpaceId?: string;
    ids?: string[];
  },
  jwt?: string,
  apikey?: string
) {
  const { agencySpaceId, targetSpaceId, ids } = options;
  const url = c.getUrl('agency', 'connection') + '?' + c.query({ agencySpaceId, targetSpaceId, id: ids });

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get agency connections failed');
  }

  const data = (await response.json()) as AgencyConnectionOutput[];
  return data.map((connection) => ({
    ...connection,
    createdAt: new Date(connection.createdAt),
  }));
}

// POST agency/connection
export async function createAgencyConnection(data: AgencyConnectionInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'connection');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create agency connection failed');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE agency/connection/{id}
export async function deleteAgencyConnection(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'connection', id);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Delete agency connection failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET agency/transaction?agencySpaceId={agencySpaceId}&targetSpaceId={targetSpaceId}
export async function getAgencyTransactions(
  options: {
    agencySpaceId?: string;
    targetSpaceId?: string;
  },
  jwt?: string,
  apikey?: string
) {
  const { agencySpaceId, targetSpaceId } = options;
  const url = c.getUrl('agency', 'transaction') + '?' + c.query({ agencySpaceId, targetSpaceId });

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get agency transactions failed');
  }

  const data = (await response.json()) as AgencyTransactionOutput[];
  return data.map((transaction) => ({
    ...transaction,
    createdAt: new Date(transaction.createdAt),
  }));
}

// POST agency/transaction
export async function createAgencyTransaction(data: AgencyTransactionInput, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'transaction');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Create agency transaction failed');
  }
}

// GET agency/payout?agencySpaceId={agencySpaceId}
export async function getAgencyPayouts(options: { agencySpaceId?: string }, jwt?: string, apikey?: string) {
  const { agencySpaceId } = options;
  const url = c.getUrl('agency', 'payout') + '?' + c.query({ agencySpaceId });

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get agency payouts failed');
  }

  const data = (await response.json()) as AgencyPayoutOutput[];
  return data.map((payout) => ({
    ...payout,
    requested: new Date(payout.requested),
    rejected: payout.rejected ? new Date(payout.rejected) : undefined,
    paid: payout.paid ? new Date(payout.paid) : undefined,
  }));
}

// POST agency/payout/request?agencySpaceId={agencySpaceId}
export async function requestAgencyPayout(data: { agencySpaceId?: string; comment: string }, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'request');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Request agency payout failed');
  }

  return (await response.json()) as OperationOutput;
}

// GET agency/payout/pending
export async function getPendingAgencyPayouts(jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'pending');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get pending agency payouts failed');
  }

  const data = (await response.json()) as AgencyPayoutOutput[];
  return data.map((payout) => ({
    ...payout,
    requested: new Date(payout.requested),
    rejected: payout.rejected ? new Date(payout.rejected) : undefined,
    paid: payout.paid ? new Date(payout.paid) : undefined,
  }));
}

// PATCH agency/payout/confirm/{id}?amount={amount}
export async function confirmAgencyPayout(
  options: {
    id: string;
    amount?: number;
  },
  jwt?: string,
  apikey?: string
) {
  const { id, amount } = options;
  const url = c.getUrl('agency', 'payout', 'confirm', id) + '?' + c.query({ amount });

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Confirm agency payout failed');
  }

  return (await response.json()) as OperationOutput;
}

// PATCH agency/payout/reject/{id}
export async function rejectAgencyPayout(id: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('agency', 'payout', 'reject', id);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Reject agency payout failed');
  }

  return (await response.json()) as OperationOutput;
}

export type AppSumoLicense = {
  id: string;
  status: 'active' | 'inactive' | 'deactivated';
  userId: string;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  claimedAt?: Date;
  tier: number;
};

// GET appsumo/license?license_id={license_id}
export async function getAppSumoLicense(license_id?: string, jwt?: string, apikey?: string) {
  let url = c.getUrl('appsumo', 'license');
  if (license_id) {
    url += '?license_id=' + license_id;
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get AppSumo license failed');
  }

  const license = (await response.json()) as AppSumoLicense;
  if (license.createdAt) {
    license.createdAt = new Date(license.createdAt);
  }
  if (license.lastUpdatedAt) {
    license.lastUpdatedAt = new Date(license.lastUpdatedAt);
  }
  if (license.claimedAt) {
    license.claimedAt = new Date(license.claimedAt);
  }
  return license;
}

// GET appsumo/can-have-team
export async function getAppSumoCanHaveTeam(jwt?: string, apikey?: string) {
  const url = c.getUrl('appsumo', 'can-have-team');

  const response = await fetch(url, {
    method: 'GET',
    headers: c.authHeader(jwt, apikey),
  });

  if (!response.ok) {
    throw new Error('Get AppSumo can-have-team failed');
  }

  return (await response.json()) as boolean;
}
