import { UserOutput } from '@bluepic/types/src/Auth/user.output';
import { logout, onLogout } from '../util/logout';
import { imgChange } from '../util/imgChange';
import { BaseActions, pinia } from './pinia';
import { Capacitor } from '@capacitor/core';
import posthog from 'posthog-js';

const jwt_storage_key = 'auth_jwt';
const current_team_storage_key = 'auth_current_team';

type AuthStoreState = {
  hydrated: boolean;
  remember: boolean;
  jwt: string | null;
  user: UserOutput | null;
  permissions: string[];
  users: Map<string, UserOutput>;
  currentTeam: string;
};

type AuthStoreActions = {
  init: (jwt: string, remember: boolean) => Promise<void>;
  tryFetchUser: () => Promise<void>;
  tryFetchPermissions: () => Promise<void>;
  initFeed: () => void;
  refreshJWT: () => Promise<void>;
  getUser(id: string): Promise<UserOutput | undefined>;
  getUsers(ids: string[]): Promise<UserOutput[]>;
  hasPermission: (permission: string) => boolean;
} & BaseActions;

export const useAuthStore = defineStore<string, AuthStoreState, {}, AuthStoreActions>('auth', {
  state: () => ({
    hydrated: false as boolean,
    remember: false as boolean,
    jwt: null as string | null,
    user: null as UserOutput | null,
    permissions: [] as string[],
    users: new Map<string, UserOutput>(),
    currentTeam: '',
  }),
  actions: {
    _hydrate() {
      try {
        let remember = false;
        let jwt = localStorage.getItem(jwt_storage_key);
        let currentTeam = localStorage.getItem(current_team_storage_key) ?? '';
        if (jwt) {
          remember = true;
        } else {
          jwt = sessionStorage.getItem(jwt_storage_key);
        }
        this.$patch({
          jwt,
          user: null,
          remember,
          currentTeam,
        });
      } catch (e) {
        console.error(e);
      }
      this.$subscribe((_, newState) => {
        try {
          if (newState.jwt === null) {
            localStorage.removeItem(jwt_storage_key);
            sessionStorage.removeItem(jwt_storage_key);
            return;
          }
          if (newState.remember) {
            localStorage.setItem(jwt_storage_key, newState.jwt);
          } else {
            sessionStorage.setItem(jwt_storage_key, newState.jwt);
          }
          if (!newState.currentTeam) {
            localStorage.removeItem(current_team_storage_key);
          } else {
            localStorage.setItem(current_team_storage_key, newState.currentTeam);
          }
        } catch {}
      });
      this.hydrated = true;
    },
    async init(jwt: string, remember: boolean) {
      this.remember = remember;
      this.jwt = jwt;
      analytics(
        'auth',
        {
          service: 'social',
          env: 'web',
          action: 'login',
        },
        jwt
      );
      await this.tryFetchUser();
    },
    async tryFetchUser() {
      if (!this.jwt) return;
      try {
        // set fetchingUser to true to prevent multiple calls to this function
        const user = await getUser(this.jwt);
        if (!user) {
          this.jwt = null;
          return;
        }

        this.user = user;
        this.users.set(user.id, user);
        await this.tryFetchPermissions();

        const hasAppSumo = await (async () => {
          try {
            const response = await fetch(`https://auth2.c2.bluepic.de/api/appsumo/license`, {
              headers: {
                Authorization: `Bearer ${this.jwt}`,
              },
            });
            if (response.ok) {
              return true;
            } else {
              return false;
            }
          } catch (err) {
            console.error(err);

            return false;
          }
        })();
        if (!user.shared) {
          const user_description = {
            email: user.email,
            name: user.name,
            has_appsumo_lifetime_deal: hasAppSumo,
            is_shared: user.shared,
          };
          console.log('!', user_description);

          posthog.identify(user.id, user_description);
        } else {
          console.log('NO IDENTIFICATION OF SHARED USER');
        }
      } catch (e) {
        console.error(e);
      }
      this.initFeed();
    },
    async tryFetchPermissions() {
      if (!this.user || !this.jwt) return;
      try {
        this.permissions = (await getAllPermissions(this.user.id, this.jwt)) ?? [];
      } catch (e) {
        console.error(e);
      }
    },
    initFeed() {
      if (!this.user || !this.jwt) return;
      useFeed(this.jwt, ['auth2', 'cloud2'], ['USER', 'PROFILE_PICTURE'], (m) => {
        switch (m.service) {
          case 'auth2':
            switch (m.operation) {
              case 'CREATE_USER':
              case 'UPDATE_USER':
                if (!m.resourceId || !m.payload) return;
                if (m.resourceId === this.user?.id) {
                  this.user = JSON.parse(m.payload);
                  if (this.user?.locked) {
                    return logout();
                  }
                  this.tryFetchPermissions();
                }
                this.users.set(m.resourceId, JSON.parse(m.payload));
                break;
              case 'DELETE_USER':
                if (!m.resourceId) return;
                if (m.resourceId === this.user?.id) {
                  return logout();
                }
                this.users.delete(m.resourceId);
                break;
            }
            break;
          case 'cloud2':
            switch (m.operation) {
              case 'SET_PROFILE_PICTURE':
                if (m.type === 'success' && m.resourceId && m.resourceId === this.user?.id) {
                  imgChange(m.resourceId);
                }
                break;
            }
            break;
        }
      });
    },
    async refreshJWT() {
      if (!this.jwt) return;
      try {
        this.jwt = await refreshCredentials(this.jwt);
      } catch (e) {
        this.jwt = null;
      }
    },
    async getUser(userId: string) {
      if (!userId || !this.jwt) return undefined;
      if (this.users.has(userId)) {
        return this.users.get(userId);
      }
      const result = await getUsers([userId], this.jwt);
      this.users.set(userId, result[0]);
      return result[0];
    },
    async getUsers(userIds: string[]) {
      if (!userIds.length || !this.jwt) return [];
      const unavailableUsers = userIds.filter((id) => !this.users.has(id));
      if (unavailableUsers.length) {
        const result = await getUsers(unavailableUsers, this.jwt);
        result.forEach((user) => this.users.set(user.id, user));
      }
      return userIds.map((id) => this.users.get(id)).filter((u): u is UserOutput => !!u);
    },
    hasPermission(permission: string) {
      if (!this.user || !this.permissions.length) return false;
      return this.permissions?.map((p: string) => p?.toUpperCase())?.includes(permission.toUpperCase());
    },
  },
});

const capacitorPlatform = ref(Capacitor.getPlatform());
const actAsNativeApp = computed(() => capacitorPlatform.value === 'ios' || capacitorPlatform.value === 'android');

onLogout((noRedirect?: boolean) => {
  useAuthStore(pinia).$reset();
  if (!noRedirect) {
    if (actAsNativeApp.value) {
      location.href = '/';
    } else {
      location.href = import.meta.env.V_ID_BASE_URL + `?logout&returnurl=${encodeURIComponent(location.href)}&auto`;
    }
  }
});
