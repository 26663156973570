import { BaseController } from './baseController';

export const creditTypes = ['APPSUMO_SOCIAL', 'APPSUMO_EMBED', 'APPSUMO_API'];
export type CreditType = (typeof creditTypes)[number];

export const limitCycles = ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'];
export type LimitCycle = (typeof limitCycles)[number];

export type CreditLogDTO = {
  userId?: string;
  timestamp?: number;
  type: 'APPSUMO_SOCIAL' | 'APPSUMO_EMBED' | 'APPSUMO_API';
  comment?: string;
  amount: number;
};

export type CreditBalance = {
  type: CreditType;
  balance: number;
  limit: {
    amount: number;
    cycle: LimitCycle;
  };
};

const baseUrl = import.meta.env.V_CREDITS_BASE_URL;

const c = new BaseController(baseUrl);

// POST /transaction/ingest
export async function logCredits(data: CreditLogDTO, jwt?: string, apikey?: string) {
  const url = c.getUrl('transaction', 'ingest');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to log credits');
  }

  return (await response.text()) as string;
}

// GET /balance
export async function getCreditBalance(types?: CreditType[], jwt?: string, apikey?: string) {
  const url = new URL(c.getUrl('balance'));
  if (types) {
    for (const type of types) {
      url.searchParams.append('type', type);
    }
  }

  const response = await fetch(url.toString(), {
    headers: {
      ...c.authHeader(jwt, apikey),
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get credit balance');
  }

  return (await response.json()) as CreditBalance[];
}
