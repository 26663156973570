<script setup lang="ts">
import { NButton, NUpload, NIcon, NUploadDragger, UploadFileInfo, useMessage, NProgress, NCollapseTransition } from 'naive-ui';
import UploadIcon from '../../../assets/icons/upload.svg';
import { fileToBlob } from '../../../util/file';
import { oneWaySync } from '../../../util/reactivity';
import { CropOptions } from '../../../controllers/cloud2Controller';
import Compressor from 'compressorjs';
import { cropImage } from '../../../util/crop';
import { fetchWithProgressV1 } from '../../../util/fetch';
const authStore = useAuthStore();
const message = useMessage();
const request = useSafeHTTP();
interface Props {
  show: boolean;
  blob?: Blob;
  crop?: CropOptions;
  userid?: string;
  teamid?: string;
}
const props = defineProps<Props>();
const { show, userid, teamid } = toRefs(props);
const emit = defineEmits(['update:show', 'close', 'update:blob', 'save']);
const _show = ref(false);
oneWaySync(_show, show);
watch(_show, (val) => {
  emit('update:show', val);
});
const uploadUrl = ref<string | null>(null);
const teamUploadUrl = ref<string | null>(null);
watchEffect(() => {
  if (!show.value || !authStore.jwt || uploadUrl.value) return;
  fetchUploadUrl();
});
function fetchUploadUrl() {
  request(
    () => {
      if (teamid?.value) {
        return getTeamPictureUploadUrl(teamid.value, authStore.jwt!);
      } else {
        return getPictureUploadUrl(authStore.jwt!);
      }
    },
    (res) => {
      console.log(res);
      if (teamid?.value) {
        teamUploadUrl.value = res;
      } else {
        uploadUrl.value = res;
      }
    }
  );
}
const device = useDevice();
const isMobile = computed(() => device.value === 'mobile' || device.value === 'tablet');
const uploadRef = ref<typeof NUpload | undefined>();
const allowedTypes = ['image/png', 'image/jpeg'];
function onChange(options: { file: UploadFileInfo; fileList: Array<UploadFileInfo>; event?: Event }) {
  if (!options?.file?.file) return;
  //20mb
  if (options.file.file.size > 20 * 1024 * 1024) {
    message.error(t('you-can-only-upload-files-up-to-16mb'));
    nextTick(() => {
      uploadRef.value?.clear();
    });
    return;
  }
  if (!allowedTypes.includes(options.file.file.type)) {
    nextTick(() => {
      uploadRef.value?.clear();
    });
    message.error(t('only-png-and-jpeg-files-are-allowed'));
    return;
  }
  imageFile.value = options.file.file;
  fileToBlob(options.file.file).then((blob) => {
    const targetSize = 10 * 1024 * 1024; //10mb
    new Compressor(blob, {
      convertSize: targetSize,
      success: (result) => {
        imageBlob.value = result;
      },
      error: (err) => {
        console.error(err);
        message.error(t('something-went-wrong'));
        uploadRef.value?.clear();
      },
    });
  });
}
const imageFile = ref<File | undefined>();
const imageBlob = ref<Blob | undefined>();
watch(imageBlob, (val) => {
  if (val) {
    emit('update:blob', val);
  }
});
const imageDataURL = computed(() => {
  if (imageBlob.value) {
    return URL.createObjectURL(imageBlob.value);
  }
  return null;
});
watch(_show, (nv) => {
  if (!nv) {
    close();
  }
});
function close() {
  imageBlob.value = undefined;
  _show.value = false;
  emit('close');
}

const crop = ref<CropOptions>({
  cropleft: 0,
  croptop: 0,
  cropwidth: 0,
  cropheight: 0,
});
const saving = ref(false);
const progress = ref(0);
type Step = 'cropping' | 'compressing' | 'uploading' | 'saving';
const step = ref<Step>('cropping');
watchEffect(() => {
  if (!saving.value) return;
  console.log(step.value);
});
async function save() {
  if (!imageBlob.value || !authStore.jwt || !authStore.user) return;
  emit('save');
  console.log(imageBlob.value.size);
  saving.value = true;
  progress.value = 0;
  step.value = 'cropping';
  console.log('yeah :)', crop.value);

  const croppedImage = await cropImage(imageBlob.value, crop.value, 'image/png');
  progress.value = 0.3;
  const url = teamid?.value ? teamUploadUrl.value : uploadUrl.value;
  if (!url) {
    throw new Error('no upload url available');
  }
  const fd = new FormData();
  if (teamid) {
    fd.append('file', croppedImage, 'pp_t_' + teamid?.value);
  } else {
    fd.append('file', croppedImage, 'pp_u_' + (userid?.value ?? authStore.user?.id));
  }
  step.value = 'uploading';

  let retries = 0;
  async function checkUpload() {
    if (retries > 5) {
      message.error(t('error'));
      return;
    }
    let uploaded = false;
    try {
      if (teamid?.value) {
        uploaded = await checkTeamPictureUpload(teamid.value, authStore.jwt!);
      } else {
        uploaded = await checkPictureUpload(authStore.jwt!);
      }
    } catch {}
    if (!uploaded) {
      retries++;
      setTimeout(checkUpload, 500);
    } else {
      progress.value = 1;
      saving.value = false;
      close();
    }
  }

  try {
    await fetchWithProgressV1({
      method: 'POST',
      url,
      data: fd,
      onUploadProgress: (uploaded, total) => {
        progress.value = 0.3 + (uploaded / total) * 0.6;
      },
    });

    step.value = 'saving';
    progress.value = 0.9;

    checkUpload();
  } catch (err) {
    console.error(err);
    message.error(t('something-went-wrong'));
    saving.value = false;
  } finally {
    uploadUrl.value = null;
  }
}
const dialog = useDialog();
</script>

<template>
  <b-adaptive-modal :title="$t('change-picture')" v-model:show="_show">
    <div class="popup">
      <div class="profile-picture-upload">
        <div v-if="imageDataURL" class="cropper-zoomer">
          <b-image-cropper
            :src="imageDataURL"
            v-model:crop="crop"
            :preserve-aspect-ratio="1"
            radius="50%"
            @error="
              (e: Error) => {
                close();
                dialog.error({ title: $t('error'), content: e.message });
              }
            "
          />
          <n-button ghost type="success" @click="save" :loading="saving">
            {{ saving ? $t(step) : $t('save') }}
          </n-button>
          <n-collapse-transition :show="saving">
            <n-progress :percentage="progress * 100" :show-indicator="false" />
          </n-collapse-transition>
        </div>
        <n-upload v-else ref="uploadRef" @change="onChange" accept=".png, .jpg, .jpeg">
          <n-upload-dragger>
            <div style="margin-bottom: 12px">
              <n-icon size="48" :depth="3">
                <upload-icon />
              </n-icon>
            </div>
            <h2 v-if="isMobile">{{ $t('tap-here-to-upload') }}</h2>
            <h2 v-else>{{ $t('click-or-drag-a-file-to-this-area-to-upload') }}</h2>
            <p style="color: var(--text-hover)">
              {{ $t('max-16mb-allowed-png-jpg-jpeg') }}
            </p>
          </n-upload-dragger>
        </n-upload>
      </div>
    </div>
  </b-adaptive-modal>
</template>

<style lang="scss" scoped>
.popup {
  @apply w-[90vw] max-w-2xl mx-auto pb-32;

  .profile-picture-upload {
    @apply grid place-items-center p-4;
    .cropper-zoomer {
      @apply w-full h-full flex flex-col gap-4 overflow-auto;
      .preview {
        @apply relative w-[90%] max-w-full mx-auto aspect-square overflow-hidden;
        .preview-img {
          @apply absolute cursor-pointer;
        }
        .overlay {
          @apply absolute inset-1;
          pointer-events: none;
          z-index: 1;
          .circle {
            @apply w-full h-full rounded-full opacity-50;
            box-shadow: 0 0 0 9999px black;
          }
        }
      }
    }
  }
}
</style>
