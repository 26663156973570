<script setup lang="ts">
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { uniq } from '../../util/uniq';
import { NTag, NCard, NButton, NIcon } from 'naive-ui';
import { isTeamAdmin } from '../../util/team';
import { UserOutput } from '@bluepic/types/src/Auth/user.output';
import EditIcon from '../../assets/icons/edit.svg';
import { SaveOutline } from '@vicons/ionicons5';
interface Props {
  team: TeamOutput;
  editable?: boolean;
  pictureEditable?: boolean;
  nameEditable?: boolean;
  showId?: boolean;
}
const props = defineProps<Props>();
const { team, editable } = toRefs(props);
const emit = defineEmits(['edit']);
const authStore = useAuthStore();
const membersIds = ref<string[]>([]);
watch(
  team,
  async (nv) => {
    if (!nv) return [];
    const allIds: string[] = [];
    if (nv.ownerId) {
      allIds.push(nv.ownerId);
    }
    if (nv.memberIds) {
      allIds.push(...nv.memberIds);
    }
    if (nv.adminIds) {
      allIds.push(...nv.adminIds);
    }
    membersIds.value = uniq(allIds);
    try {
      authStore.getUsers(allIds);
    } catch {}
  },
  { immediate: true, deep: true }
);
const allMembers = computed(() => {
  return membersIds.value.map((id) => authStore.users.get(id)).filter((u) => u !== undefined && !u.dummy) as UserOutput[];
});
const showInvitePopup = ref(false);

function addMember() {
  showInvitePopup.value = true;
}
function edit() {
  emit('edit', team.value);
}
const isAdmin = computed(() => {
  return isTeamAdmin(team.value);
});
const showChangePBModal = ref(false);
const editingName = ref(false);
const newName = ref(team.value.name);
watch(
  team,
  (nv) => {
    if (!nv) return;
    newName.value = nv.name;
  },
  { immediate: true }
);
const request = useSafeHTTP();
const message = useMessage();
const saving = ref(false);
function saveName() {
  return new Promise<void>((resolve) => {
    if (!team.value || !authStore.jwt) {
      return;
    }
    if (newName.value === team.value.name) {
      return;
    }
    saving;
    request(
      () =>
        updateTeam(
          team.value.id,
          {
            name: newName.value,
          },
          authStore.jwt!
        ).finally(() => {
          resolve();
          saving.value = false;
          editingName.value = false;
        }),
      (res) => {
        console.log(res);
        message.success(t('saved'));
      }
    );
  });
}
</script>

<template>
  <div>
    <n-card
      class="team-profile"
      :segmented="{
        content: true,
      }"
    >
      <template #header>
        <div class="header">
          <div class="w-full flex justify-between">
            <div class="avatar-container">
              <profile-picture class="avatar" :teamid="team?.id" />
              <n-button
                v-if="pictureEditable && !team.locked"
                class="-bottom-4 -right-16"
                circle
                size="small"
                type="primary"
                tertiary
                @click="showChangePBModal = true"
              >
                <template #icon>
                  <n-icon>
                    <edit-icon />
                  </n-icon>
                </template>
              </n-button>
            </div>
            <!-- <div class="flex gap-4 items-center">
              <n-button v-if="!team.locked && editable" round @click="edit">
                <n-icon class="mr-2">
                  <settings-icon />
                </n-icon>
                {{ $t("settings") }}
              </n-button>
              <slot name="header-extra" />
            </div> -->
          </div>
          <div class="flex items-center gap-2">
            <n-input v-if="editingName" v-model:value="newName" @keydown.enter.prevent="saveName" />
            <h1 v-else>{{ team?.name }}</h1>
            <n-button
              v-if="nameEditable && !team.locked"
              circle
              size="small"
              :loading="saving"
              :disabled="saving"
              :type="editingName ? 'success' : undefined"
              ghost
              @click="
                () => {
                  if (!editingName) {
                    editingName = true;
                  } else {
                    //save
                    saveName();
                  }
                }
              "
            >
              <template #icon>
                <t-fade>
                  <n-icon size="medium" v-if="editingName">
                    <save-outline />
                  </n-icon>
                  <n-icon size="medium" v-else>
                    <edit-icon />
                  </n-icon>
                </t-fade>
              </template>
            </n-button>
            <n-tag v-if="team.locked" type="error" round>{{ $t('locked') }}</n-tag>
            <n-tag v-if="team.agency" type="warning" round>{{ $t('agency') }}</n-tag>
          </div>
          <div v-if="showId" class="flex items-center gap-4">
            <span class="w-8">Id:</span>
            <b-clipboard :value="team.id" />
          </div>
        </div>
      </template>
      <div class="flex flex-col gap-4">
        <div class="member-list">
          <div class="flex items-center justify-between">
            <h2>{{ $t('members') }}:</h2>
            <n-button v-if="!team.locked && isAdmin" circle ghost @click="addMember" type="primary"> + </n-button>
          </div>
          <b-paginated-list :items-per-page="5">
            <user-list-item v-for="user in allMembers" :key="user.id" :user="user" :team="team" />
          </b-paginated-list>
        </div>
        <slot />
      </div>
    </n-card>
    <invite-modal :team-id="team.id" v-model:show="showInvitePopup" />
    <profile-picture-upload v-model:show="showChangePBModal" :teamid="team.id" />
  </div>
</template>

<style lang="scss" scoped>
.team-profile {
  @apply mt-8;
  .header {
    @apply h-24 flex flex-col justify-between items-center;
    .avatar-container {
      @apply relative h-0 w-full;
      .avatar {
        @apply absolute w-24 h-24 top-[-3rem];
      }
    }
  }
  .member-list {
    @apply flex flex-col gap-4;
  }
}
</style>
