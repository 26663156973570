import { BaseController } from './baseController';

export interface LogDTO {
  service: string;
  env: string;
  action: string;
  data?: any;
}

const baseUrl = import.meta.env.V_ANALYTICS_BASE_URL;

const c = new BaseController(baseUrl);

// POST /api/{type}
export async function analytics(type: string, data: LogDTO, jwt?: string, apikey?: string) {
  const url = c.getUrl(type);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to send analytics');
  }

  return (await response.text()) as string;
}
