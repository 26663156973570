export async function fetchWithProgress(
  url: string,
  options: RequestInit,
  data: ReadableStream | Blob | undefined,
  uploadProgress: (uploadedBytes: number, totalBytes: number) => void,
  onProgress: (downloadBytes: number, totalBytes?: number) => void
) {
  const totalBytes =
    typeof data === 'string'
      ? new TextEncoder().encode(data).length
      : data instanceof Blob
      ? data.size
      : data instanceof ArrayBuffer
      ? data.byteLength
      : 0;

  // Wrap the input data in a ReadableStream to monitor progress
  let uploadedBytes = 0;
  const stream =
    data !== undefined
      ? new ReadableStream({
          start(controller) {
            if (data instanceof ReadableStream) {
              // If data is already a ReadableStream, pipe it through
              const reader = data.getReader();
              console.log('reader', reader);

              return reader.read().then(function process({ done, value }): any {
                console.log(done, value);

                if (done) {
                  controller.close();
                  return;
                }
                uploadedBytes += value?.length || 0;
                uploadProgress(uploadedBytes, totalBytes);
                controller.enqueue(value);
                return reader.read().then(process);
              });
            } else if (data instanceof Blob) {
              // For Blob, use slicing to stream the data
              const reader = data.stream().getReader();
              console.log('reader', reader);
              return reader.read().then(function process({ done, value }): any {
                console.log(done, value);
                if (done) {
                  controller.close();
                  return;
                }
                uploadedBytes += value?.length || 0;
                onProgress(uploadedBytes, totalBytes);
                controller.enqueue(value);
                return reader.read().then(process);
              });
            }
            // else {
            //   // If it's a string or ArrayBuffer
            //   const encoder = new TextEncoder();
            //   const encodedData = encoder.encode(data);
            //   uploadedBytes += encodedData.byteLength;
            //   onProgress(uploadedBytes, totalBytes);
            //   controller.enqueue(encodedData);
            //   controller.close();
            // }
          },
        })
      : undefined;

  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Length': totalBytes.toString(),
      'Content-Type':
        data instanceof Blob ? data.type : ((options.headers as any) ?? {})['Content-Type'] ?? 'application/octet-stream',
    },
    body: stream,
    duplex: 'half',
  } as any);

  if (!response.body) {
    return {
      contentType: response.headers.get('Content-Type'),
      response,
      data: new Uint8Array(),
    };
  }

  const reader = response.body.getReader();

  const contentType = response.headers.get('Content-Type');

  const contentLength = contentType ? Number(response.headers.get('Content-Length')) : undefined;
  let receivedLength = 0;
  let chunks: Uint8Array[] = [];

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    if (value) {
      chunks.push(value);
      receivedLength += value.length;
      onProgress(receivedLength, contentLength);
    }
  }

  const chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  for (const chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  return {
    contentType,
    response,
    data: chunksAll,
  };
}

type FetchWithProgressOptions = {
  url: string;
  method?: string; // Default: "GET"
  data?: XMLHttpRequestBodyInit | Document | null; // Restrict to supported types
  headers?: Record<string, string>; // Optional headers
  onUploadProgress?: (uploaded: number, total: number) => void; // Upload progress callback
  onDownloadProgress?: (downloaded: number, total: number) => void; // Download progress callback
};

export function uint8Array2Str(data: Uint8Array): string {
  return new TextDecoder().decode(data);
}

export function fetchWithProgressV1({
  url,
  method = 'GET',
  data = null,
  headers = {},
  onUploadProgress,
  onDownloadProgress,
}: FetchWithProgressOptions): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';

    // Open the request
    xhr.open(method, url);

    // Set headers
    for (const [key, value] of Object.entries(headers)) {
      xhr.setRequestHeader(key, value);
    }

    // Handle upload progress
    if (onUploadProgress && xhr.upload) {
      xhr.upload.onprogress = (event) => {
        onUploadProgress(event.loaded, event.total);
      };
    }

    // Handle download progress
    if (onDownloadProgress) {
      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          onDownloadProgress(event.loaded, event.total);
        }
      };
    }

    // Handle request completion
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response); // Resolve with the XMLHttpRequest object
      } else {
        reject(new Error(`HTTP error: ${xhr.status}`));
      }
    };
    // Handle network errors
    xhr.onerror = () => reject(new Error('Network error'));

    // Send the request
    xhr.send(data); // Type-safe data
  });
}
