export function debounce(fn: Function, delay: number): (...args: any[]) => void {
  let timeoutID: ReturnType<typeof setTimeout> | null = null;
  // @ts-ignore  // TS2339: Property 'apply' does not exist on type 'Function'.
  const self = this;
  return () => {
    clearTimeout(timeoutID!);
    var args = arguments;
    timeoutID = setTimeout(() => {
      fn.apply(self, args);
    }, delay);
  };
}
