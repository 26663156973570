<script setup lang="ts">
import { BxAvatar } from '@bluepic/fields';
import '@bluepic/fields/style.css';
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { UserOutput } from '@bluepic/types/src/Auth/user.output';

interface Props {
  userid?: string;
  teamid?: string;
  user?: UserOutput;
  team?: TeamOutput;
  square?: boolean;
  thumbnail?: boolean;
}
const props = defineProps<Props>();
const { userid, teamid, user, team, square, thumbnail } = toRefs(props);
const teamStore = useTeamStore();
watch(teamid!, (nv) => {
  if (!nv) return;
  teamStore.getTeam(nv);
});
const authStore = useAuthStore();
if (userid) {
  watch(userid, (nv: string | undefined) => {
    if (nv) {
      authStore.getUser(nv);
    }
  });
}
const _team = computed(() => {
  if (userid?.value || user?.value) return;
  return team?.value ?? teamStore.teams.find((t) => t.id === teamid?.value);
});
const _user = computed(() => {
  if (teamid?.value || team?.value) return;
  if (user?.value) return user.value;
  if (!userid?.value) {
    return authStore.user;
  }
  return authStore.users.get(userid.value);
});
</script>

<template>
  <div class="profile-picture">
    <BxAvatar :user="_user ?? undefined" :team="_team" :thumbnail="thumbnail" :square="square" />
  </div>
</template>

<style lang="scss" scoped>
.profile-picture {
  @apply w-full h-full;
  .bx-avatar {
    @apply w-full h-full;
  }
}
</style>
