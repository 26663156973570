/**
 * Erzeugt eine duplikatfreie Version des Arrays (bzw. Array-ähnlichen Objekts),
 * wobei nur das erste Vorkommen jedes Elements beibehalten wird.
 *
 * @category Array
 * @param array - Das Array (oder Array-ähnliche Objekt), aus dem Duplikate entfernt werden.
 * @returns Ein neues Array ohne doppelte Einträge.
 * @example
 *   uniq([2, 1, 2]) // => [2, 1]
 */

// Wir definieren unser eigenes "List"-Alias (ähnlich wie in Lodash).
type List<T> = ArrayLike<T>;

export function uniq<T>(array: List<T> | null | undefined): T[] {
  if (!array) {
    return [];
  }

  const seen = new Set<T>();
  const result: T[] = [];

  for (let i = 0; i < array.length; i++) {
    const value = array[i];
    if (!seen.has(value)) {
      seen.add(value);
      result.push(value);
    }
  }

  return result;
}
