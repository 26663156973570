import { InviteOutput } from '@bluepic/types/src/Auth/invite.output';
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { Theme } from '../util/theme';
import { upsert } from '../util/arrays';
import { onLogout } from '../util/logout';
import { BaseActions, pinia } from './pinia';
import { ComputedRef } from 'vue';

type TeamStoreState = {
  hydrated: boolean;
  init: boolean;
  currentTeamId: string | null;
  teams: TeamOutput[];
  teamInvites: Map<string, InviteOutput[]>;
  incomingInvites: Map<string, InviteOutput>;
  fetching: boolean;
};

type TeamStoreActions = {
  getCurrentTeam: () => ComputedRef<TeamOutput | undefined>;
  initTeamTheme: () => void;
  tryFetch: (force?: boolean) => void;
  getTeam: (teamId: string, refetch?: boolean) => Promise<TeamOutput | undefined>;
  fetchSubTeams: (teamId: string) => Promise<void>;
  tryFetchInvites: (teamId: string) => Promise<void>;
  tryFetchIncomingInvites: () => Promise<void>;
  initFeed: () => void;
  watchTeam: () => void;
} & BaseActions;

let stopWatchingTeam: () => void;
let unsubscribeFromFeed: () => void;
let abortFetch: (reason?: string) => void;
export const useTeamStore = defineStore<string, TeamStoreState, {}, TeamStoreActions>('team', {
  state: () => ({
    hydrated: false,
    init: false,
    currentTeamId: null,
    teams: [] as TeamOutput[],
    teamInvites: new Map<string, InviteOutput[]>(),
    incomingInvites: new Map<string, InviteOutput>(),
    fetching: false,
  }),
  actions: {
    _hydrate() {
      this.$patch({
        hydrated: true,
      });
    },
    getCurrentTeam() {
      return computed(() => {
        if (!this.currentTeamId) return;
        return this.teams?.find((t) => t.id === this.currentTeamId);
      });
    },
    async initTeamTheme() {
      const authStore = useAuthStore();
      const getTeamTheme = async (team: TeamOutput): Promise<Theme | undefined> => {
        if (team?.theme) {
          if (team.noSubscriptionNeeded) return new Theme(JSON.parse(team.theme));
          if (!authStore.jwt) throw new Error('no jwt');
          try {
            const subscription = await getTeamSubscriptionByTeam(team.id, authStore.jwt);
            if (subscription?.theme) {
              const theme = new Theme(JSON.parse(team.theme));
              return theme;
            }
          } catch (e) {
            console.error(e);
          }
        }
        if (team?.parentId) {
          const parent = await this.getTeam(team.parentId);
          if (parent) return getTeamTheme(parent);
        }
        return;
      };
      const themeStore = useThemeStore();
      watch(this.getCurrentTeam(), async (team) => {
        if (!team) return;
        const theme = await getTeamTheme(team);
        if (theme) themeStore.teamTheme = theme;
        else themeStore.teamTheme = null;
      });
    },
    tryFetch(force = false) {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      if (this.fetching) {
        if (!force) {
          return;
        }
        abortFetch?.('force');
      }
      this.fetching = true;
      abortFetch = getTeams({
        jwt: authStore.jwt,
        success: (result) => {
          if (result) {
            const ids = result.map((r) => r.id);
            this.teams = this.teams.filter((t) => !ids.includes(t.id));
            this.teams.push(...result);
          }
          this.initFeed();
          this.initTeamTheme();
          this.init = true;
        },
        error: (e) => {
          console.error(e);
        },
        finally: () => {
          this.fetching = false;
        },
      });
    },
    getTeam(teamId: string, refetch = false) {
      return new Promise<TeamOutput | undefined>((resolve, reject) => {
        if (!refetch && this.teams.map((t) => t.id).includes(teamId)) {
          return resolve(this.teams.find((t) => t.id === teamId));
        }
        const authStore = useAuthStore();
        if (!authStore.jwt) throw new Error('no jwt');
        this.fetching = true;
        getTeams({
          teamIds: [teamId],
          jwt: authStore.jwt,
          success: (result) => {
            const team = result?.[0];
            upsert(this.teams, (t) => t.id === team.id, team);
            resolve(team);
          },
          error: (e) => {
            console.error(e);
            reject(e);
          },
          finally: () => {
            this.fetching = false;
          },
        });
      });
    },
    fetchSubTeams(teamId: string) {
      return new Promise<void>((resolve, reject) => {
        const authStore = useAuthStore();
        if (!authStore.jwt) {
          reject('no jwt');
          return;
        }
        const stopWatching = watch(
          this.$state,
          (nv) => {
            if (nv.fetching) {
              return;
            }
            nextTick(() => {
              stopWatching();
            });
            this.fetching = true;
            getTeams({
              teamIds: [teamId],
              recursive: true,
              jwt: authStore.jwt!,
              success: (result) => {
                const ids = result.map((r) => r.id);
                this.teams = this.teams.filter((t) => !ids.includes(t?.id ?? ''));
                if (Array.isArray(result)) {
                  this.teams.push(...result);
                } else {
                  this.teams.push(result);
                }
                resolve();
              },
              error: (e) => {
                console.error(e);
                reject(e);
              },
              finally: () => {
                this.fetching = false;
                resolve();
              },
            });
          },
          { immediate: true }
        );
      });
    },
    async tryFetchInvites(teamId: string) {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      try {
        const results = await getInvitesByTeam(teamId, authStore.jwt);
        if (results) {
          this.teamInvites.set(teamId, results);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async tryFetchIncomingInvites() {
      const authStore = useAuthStore();
      if (!authStore.jwt) return;
      try {
        if (!authStore.user) return;
        const results = await getInvitesByUser(authStore.user.id, authStore.jwt);
        if (results) {
          for (const invite of results) {
            this.incomingInvites.set(invite.id, invite);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    initFeed() {
      const authStore = useAuthStore();
      const teamStore = useTeamStore();
      if (!authStore.jwt) return;
      unsubscribeFromFeed?.();
      unsubscribeFromFeed = useFeed(authStore.jwt, ['auth2', 'cloud2'], ['TEAM', 'INVITE', 'TEAM_PICTURE'], (m) => {
        if (!m) {
          console.log('no message');
          return;
        }
        switch (m.service) {
          case 'auth2':
            switch (m.resource) {
              case 'TEAM':
                switch (m.operation) {
                  case 'CREATE_TEAM':
                  case 'UPDATE_TEAM':
                    // console.log(m.operation);
                    if (!m.payload || !m.resourceId) return;
                    const i = JSON.parse(m.payload) as TeamOutput;
                    // console.log(m.operation, i);
                    upsert(this.teams, (t) => t.id === i.id, i);
                    if (!authStore.user) return;
                    if (authStore.currentTeam === m.resourceId) {
                      authStore.tryFetchPermissions();
                    }
                    break;
                  case 'DELETE_TEAM':
                    if (!m.resourceId) return;
                    teamStore.teams = teamStore.teams.filter((t) => t.id !== m.resourceId);
                    break;
                }
                break;
              case 'INVITE':
                switch (m.operation) {
                  case 'CREATE_INVITE':
                    // console.log(m.operation, m.payload);
                    if (!m.payload || !m.resourceId) return;
                    const authStore = useAuthStore();
                    const invite = JSON.parse(m.payload) as InviteOutput;
                    if (authStore.user?.email === invite.email) {
                      teamStore.incomingInvites.set(invite.id, invite);
                      return;
                    }
                    if (!teamStore.teamInvites.has(invite.teamId)) {
                      teamStore.teamInvites.set(invite.teamId, []);
                    }
                    const invites = teamStore.teamInvites.get(invite.teamId);
                    if (!invites) return;
                    upsert(invites, (i: InviteOutput) => i.id === invite.id, invite);
                    break;
                  case 'REDEEM_INVITE':
                  case 'REVOKE_INVITE':
                  case 'DECLINE_INVITE':
                    // console.log(m.operation, m.payload);
                    if (!m.resourceId) return;
                    const i = [...(teamStore.teamInvites as Map<string, InviteOutput[]>).values()]
                      .flat()
                      .find((i) => i.id === m.resourceId);
                    if (i) {
                      if (m.operation === 'REDEEM_INVITE') {
                        teamStore.getTeam(i.teamId);
                      }
                      const index = teamStore.teamInvites.get(i.teamId)?.indexOf(i);
                      if (index === undefined) return;
                      teamStore.teamInvites.get(i.teamId)?.splice(index, 1);
                    }
                    teamStore.incomingInvites.delete(m.resourceId);
                    break;
                }
                break;
            }
            break;
        }
      });
    },
    watchTeam() {
      stopWatchingTeam?.();
      const authStore = useAuthStore();
      stopWatchingTeam = watch(
        authStore.$state,
        (nv) => {
          if (!this.init || (nv.user && nv.currentTeam != this.currentTeamId)) {
            this.currentTeamId = String(nv.currentTeam);
            this.tryFetch(true);
          }
        },
        { immediate: true, deep: true }
      );
    },
  },
});

onLogout(() => {
  useTeamStore(pinia).$reset();
});
