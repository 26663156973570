import { CropOptions } from '../controllers/cloud2Controller';

/**
 * Schneidet ein Bild basierend auf den angegebenen Crop-Parametern zu.
 * @param crop - Das Crop-Objekt mit den Zuschneideparametern.
 * @param imageBlob - Das Bild als Blob.
 * @returns Ein Promise, das das zugeschnittene Bild als Blob enthält.
 */
export function cropImage(imageBlob: Blob, crop: CropOptions, type: 'image/png' | 'image/jpeg'): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(imageBlob);

    img.onload = () => {
      // Erstelle ein Canvas mit den Crop-Größen
      const canvas = document.createElement('canvas');
      canvas.width = crop.cropwidth;
      canvas.height = crop.cropheight;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Canvas-Kontext konnte nicht abgerufen werden.'));
        return;
      }

      // Zeichne das zugeschnittene Bild auf das Canvas
      ctx.drawImage(img, crop.cropleft, crop.croptop, crop.cropwidth, crop.cropheight, 0, 0, crop.cropwidth, crop.cropheight);

      // Konvertiere das Canvas zurück in einen Blob
      canvas.toBlob((croppedBlob) => {
        if (croppedBlob) {
          resolve(croppedBlob);
        } else {
          reject(new Error('Das zugeschnittene Bild konnte nicht erstellt werden.'));
        }
        // Bereinige die Objekt-URL
        URL.revokeObjectURL(url);
      }, type);
    };

    img.onerror = () => {
      reject(new Error('Das Bild konnte nicht geladen werden.'));
      // Bereinige die Objekt-URL
      URL.revokeObjectURL(url);
    };

    // Setze die Quelle des Bildes auf die Blob-URL
    img.src = url;
  });
}
