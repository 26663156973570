<script setup lang="ts">
import {
  NConfigProvider,
  NThemeEditor,
  darkTheme,
  NDialogProvider,
  NMessageProvider,
  NSpace,
  NButton,
  NCheckbox,
  DialogReactive,
} from 'naive-ui';
import { throttle } from 'lodash';
import { NPopupProvider } from 'naive-tools';
import 'naive-tools/style.css';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { dialog } from './util/naiveUI';
import { useIdle } from '@vueuse/core';
import { logout } from './util/logout';
import { BluepicSpinner } from '@bluepic/fields';
import '@bluepic/fields/style.css';
import customDomains from '../CUSTOM_DOMAINS.yaml';
import { CookieBanner } from '@bluepic/privacy';
import '@bluepic/privacy/style.css';
import { cookieSettings } from './main';
import { AppSumoLicense } from './controllers/authController';
import { CreditBalance } from './controllers/creditsController';
// const { fetchingConfig, config } = useConfig();
// //@ts-ignore
// window.config = () => {
//   if (fetchingConfig.value) {
//     return 'fetching config';
//   }
//   // @ts-ignore
//   return Object.fromEntries(Object.entries(config.value));
// };

const PLATFORM = Capacitor.getPlatform();

if (PLATFORM !== 'web') {
  const themeStore = useThemeStore();
  // @ts-ignore
  window.open = async function (url, target, features) {
    if (!url) return;
    await Browser.open({
      url: url.toString(),
      windowName: target,
      toolbarColor: themeStore.background().value,
    });
  };
}
const fetching = ref(false);
const nativeKeyboardHeight = ref(0);
provide('nativeKeyboardHeight', nativeKeyboardHeight);
onMounted(async () => {
  if (PLATFORM === 'web') return;
  await StatusBar.setStyle({ style: Style.Dark });
  if (Keyboard) {
    Keyboard.setResizeMode({ mode: KeyboardResize.None });
    Keyboard.addListener('keyboardWillShow', ({ keyboardHeight }) => {
      nativeKeyboardHeight.value = keyboardHeight;
    });
    Keyboard.addListener('keyboardWillHide', () => {
      nativeKeyboardHeight.value = 0;
    });
  }
  App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    const url = new URL(event.url);
    if (url.searchParams.has('token')) {
      const token = url.searchParams.get('token');
      if (token) {
        localStorage.setItem('auth_jwt', token);
        authStore.jwt = token;
        fetching.value = true;
        await authStore.tryFetchUser();
        //InAppBrowser.close();
        router.push('/');
        fetching.value = false;
        // InAppBrowser.close();
      }
    } else {
      router.push(url.pathname);
    }
  });
});

// watchEffect(() => {
//   console.log('nativeKeyboardHeight', nativeKeyboardHeight.value);
// });

const safeInsets = reactive({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});
SafeArea.getSafeAreaInsets().then(({ insets }) => {
  if (PLATFORM !== 'ios') return;
  safeInsets.top = insets.top;
  safeInsets.bottom = insets.bottom;
  safeInsets.left = insets.left;
  safeInsets.right = insets.right;
});

const minPopupOffset = computed(() => safeInsets.top + 20);

watchEffect(() => {
  useCssVar('--safe-area-inset-top').value = `${safeInsets.top}px`;
  useCssVar('--safe-area-inset-bottom').value = `${nativeKeyboardHeight.value > 0 ? 0 : safeInsets.bottom}px`;
  useCssVar('--safe-area-inset-left').value = `${safeInsets.left}px`;
  useCssVar('--safe-area-inset-right').value = `${safeInsets.right}px`;
});

SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
  useCssVar('--status-bar-height').value = `${statusBarHeight}px`;
});

//initFirebase();

const themeStore = useThemeStore();
themeStore.init();
const templateStore = useTemplateStore();
templateStore.watchCurrentTeam();
const savedFontSize = useLocalStorage('fontSize', '14px');
watch(
  savedFontSize,
  (nv) => {
    if (nv) {
      useCssVar('--root-font-size').value = nv;
    }
  },
  { immediate: true }
);
const tDuration = useMotion();
watch(
  tDuration,
  (nv) => {
    if (nv) {
      useCssVar('--t-dur').value = `${nv}ms`;
    }
  },
  { immediate: true }
);
const route = useRoute();
const noNav = ['/editor', '/invite', '/affiliate', '/onboarding', '/drafts', '/shared', '/forbidden'];
const showNav = computed(() => {
  for (const path of noNav) {
    if (route.path.startsWith(path)) {
      return false;
    }
  }
  return true;
});

const isDev = computed(() => import.meta.env.V_ENV !== 'PROD');
const dontShowDevWarning = useLocalStorage('dontShowDevWarning', false);
if (isDev.value && !dontShowDevWarning.value) {
  const notAgain = ref(false);
  const d = dialog.warning({
    title: t('development-build'),
    content: () =>
      h('p', [
        t('dev-warning-part-1'),
        h('a', { href: 'https://social.bluepic.io' }, 'https://social.bluepic.io'),
        t('dev-warning-part-2'),
      ]),
    action: () =>
      h(
        NSpace,
        {
          style: 'width: 100%',
          vertical: true,
          wrap: false,
        },
        () => [
          h(
            NCheckbox,
            {
              checked: notAgain.value,
              onChange: (v) => {
                notAgain.value = v;
              },
            },
            () => t('dont-show-again')
          ),
          h(
            NSpace,
            {
              align: 'center',
              justify: 'end',
              wrap: false,
            },
            () => [
              h(
                NButton,
                {
                  onClick: () => {
                    location.href = 'https://social.bluepic.io';
                  },
                },
                () => t('go-to-stable')
              ),
              h(
                NButton,
                {
                  onClick: () => {
                    d.destroy();
                    dontShowDevWarning.value = notAgain.value;
                  },
                  type: 'warning',
                  style: 'color: var(--background)',
                },
                () => 'OK'
              ),
            ]
          ),
        ]
      ),
  });
}
const authStore = useAuthStore();
const appsumoLicense = ref<AppSumoLicense | null>(null);
provide('appsumoLicense', appsumoLicense);

const balances = ref<CreditBalance[] | null>(null);
provide('creditbalances', balances);

watch(
  () => authStore.jwt,
  (nv) => {
    if (nv) {
      getAppSumoLicense(undefined, nv)
        .then((res) => {
          if (res) {
            appsumoLicense.value = res;
          }
        })
        .catch(() => {
          console.error('failed to get appsumo license');
        });
      getCreditBalance(['APPSUMO_SOCIAL'], nv)
        .then((b) => {
          balances.value = b;
        })
        .then(() => {
          console.error('failed to get credit balance');
        });
    }
  },
  { immediate: true }
);

let stopWatchingIdle: (() => void) | undefined;
let stopWatchingTimeout: (() => void) | undefined;
const now = ref(Date.now());
let interval: NodeJS.Timer;
onMounted(() => {
  interval = setInterval(() => {
    now.value = Date.now();
  }, 1000);
});
onUnmounted(() => {
  //@ts-ignore timer is not a number
  clearInterval(interval);
});
const router = useRouter();
const { idle, lastActive } = useIdle((parseInt(import.meta.env.V_SOCIAL_SHARED_SESSION_TIMEOUT_MINUTES!) / 2) * 60 * 1000);
const timeout = computed(() => {
  const timeRemaining =
    parseInt(import.meta.env.V_SOCIAL_SHARED_SESSION_TIMEOUT_MINUTES!) * 60 * 1000 - (now.value - lastActive.value);
  return new Date(timeRemaining);
});
const remainingSeconds = computed(() => Math.trunc(timeout.value.getTime() / 1000));
if (stopWatchingIdle) {
  stopWatchingIdle();
}
stopWatchingTimeout = watch(remainingSeconds, (nv) => {
  if (!authStore.user?.shared) return;
  if (nv <= 0) {
    d?.destroy();
    if (sessionStore.currentSessionId && authStore.jwt) {
      killSession(sessionStore.currentSessionId, authStore.jwt).catch(() => {
        console.log('failed to kill session');
      });
    }
    const path = `/shared/${authStore.user?.id}?timeout`;
    logout(true);
    router.push(path);
  }
});
let d: DialogReactive;
stopWatchingIdle?.();
stopWatchingIdle = watch(
  idle,
  throttle((nv, ov) => {
    try {
      d?.destroy();
    } catch {}
    if (!authStore.user?.shared) {
      return;
    }
    if (nv && !ov) {
      d = dialog.warning({
        title: t('session-timeout'),
        content: () =>
          h(
            'p',
            t('you-will-be-signed-out-in-timeout-value-getminutes-timeout-value-getseconds', [
              timeout.value?.getMinutes(),
              timeout.value?.getSeconds(),
            ])
          ),
        closable: false,
      });
    } else if (!nv) {
      try {
        if (!authStore.jwt) return;
        ping();
      } catch {}
    }
  }, 100)
);
const sessionStore = useSessionStore();
const notificationStore = useNotificationStore();
watch(
  () => authStore.jwt,
  (nv) => {
    sessionStore.tryFetch();
  },
  { immediate: true }
);
const stopWatchingUser = watch(
  () => authStore.user,
  (nv) => {
    if (!nv) return;
    if (!nv.shared) {
      notificationStore.tryFetch();
    }
    nextTick(() => {
      stopWatchingUser();
    });
  },
  { immediate: true }
);
const ping = throttle(() => {
  if (!authStore.jwt) return;
  pingSession(authStore.jwt).then((res) => {
    console.log('ping', res);
  });
}, 1000);
// @ts-ignore
window.ping = ping;

// useFirebase(
//   {
//     apiKey: import.meta.env.V_FIREBASE_APIKEY,
//     authDomain: import.meta.env.V_FIREBASE_AUTH_DOMAIN,
//     projectId: import.meta.env.V_FIREBASE_PROJECT_ID,
//     storageBucket: import.meta.env.V_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: import.meta.env.V_FIREBASE_MESSAGING_SENDER_ID,
//     appId: import.meta.env.V_FIREBASE_APPID,
//     measurementId: import.meta.env.V_FIREBASE_MEASUREMENT_ID,
//   },
//   'SOCIAL',
//   import.meta.env.V_FIREBASE_VAPID_KEY,
//   PLATFORM === 'web' ? 'web' : 'capacitor',
//   computed(() => authStore.jwt ?? undefined)
// );

// firebase.addEventListener('notification', (notificationEvent) => {
//   console.log('notificationEvent', notificationEvent);
// });
// firebase.addEventListener('notificationAction', (notificationActionEvent) => {
//   console.log('notificationActionEvent', notificationActionEvent);
//   window.alert('notificationActionEvent');
// });
const showCookieBanner = ref(false);
(window as any).showCookieBanner = showCookieBanner;

watch(
  () => authStore.user,
  () => {
    if (authStore.user) {
      cookieSettings.value = {
        analytics: true,
        marketing: true,
        necessary: true,
      };
    }
  },
  { immediate: true }
);
// const consentLSKey = import.meta.env.V_CONSENT_LS_KEY;
// const LScookieSettings = useLocalStorage(consentLSKey, null);
// watch(
//   LScookieSettings,
//   (nv) => {
//     const parsed = JSON.parse(nv ?? 'null');
//     if (!parsed) {
//       showCookieBanner.value = true;
//       return;
//     }
//     if (parsed.version !== cookiePolicyVersion) {
//       showCookieBanner.value = true;
//       return;
//     }
//   },
//   { immediate: true }
// );
const _locale = computed(() => {
  //only give first part of localestring
  return locale.value.split('-')[0];
});

const redirect = customDomains[window.location.hostname];
if (redirect) {
  router.push(redirect);
}

const darkmode = ref(true);
provide('darkmode', darkmode);
</script>

<template>
  <div
    class="index bluepic-app-wrapper"
    :class="{ darkmode }"
    :style="{
      height: PLATFORM === 'ios' ? `calc(100% + ${0}px)` : '100%',
    }"
  >
    <n-config-provider :theme="darkTheme" :theme-overrides="themeStore.themeOverrides().value">
      <!-- <n-theme-editor> -->
      <b-confetti-provider>
        <n-dialog-provider>
          <n-message-provider>
            <banner-provider>
              <email-collector>
                <onboarding-provider>
                  <b-overlay-provider>
                    <n-popup-provider :min-offset="minPopupOffset" :max-offset="55">
                      <div class="page-wrapper">
                        <layout-nav :show="showNav">
                          <router-view v-slot="{ Component, route }">
                            <cookie-banner
                              v-model:show="showCookieBanner"
                              @update:cookie-settings="cookieSettings = $event"
                              :locale="(_locale as any)"
                              darkmode
                            />
                            <transition name="page" appear @after-enter="(route.meta as any).afterEnter?.()">
                              <component :is="Component" />
                            </transition>
                            <div v-if="!authStore.user" class="fetching-user-loading-wrapper">
                              <bluepic-spinner />
                            </div>
                          </router-view>
                        </layout-nav>
                      </div>
                    </n-popup-provider>
                  </b-overlay-provider>
                </onboarding-provider>
              </email-collector>
            </banner-provider>
          </n-message-provider>
        </n-dialog-provider>
      </b-confetti-provider>
      <!-- </n-theme-editor> -->
    </n-config-provider>
  </div>
</template>

<style lang="scss">
#app {
  @apply h-full overflow-hidden p-0 m-0;
}
#beacon-container {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.index {
  @apply relative w-full font-normal text-base;
  :deep().popups-wrapper {
    @apply h-full z-[10];
  }
  .n-popup__root {
    @apply h-0;
  }
  .n-popup-provider {
    @apply h-full;
  }
  :deep().app-wrapper {
    @apply h-full;
  }
  .page-wrapper {
    @apply relative w-full h-full overflow-hidden;
  }
}
.fetching-user-loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
