<script setup lang="ts">
import { CropOptions } from '../../controllers/cloud2Controller';
import { throttle } from 'lodash';

interface Props {
  src: string;
  crop: CropOptions;
  radius?: string;
  preserveAspectRatio?: boolean | number;
  minWidth?: number;
  minHeight?: number;
}
const props = defineProps<Props>();
const { src, crop, radius, preserveAspectRatio, minWidth, minHeight } = toRefs(props);
const emit = defineEmits(['update:crop', 'error']);
const size = ref<{ width: number; height: number }>({ width: 0, height: 0 });
const scale = ref(1);
watch(size, (s) => {
  emit(
    'update:crop',
    assertAspectRatio({
      cropleft: 0,
      croptop: 0,
      cropwidth: s.width,
      cropheight: s.height,
    })
  );
});
const imgRef = ref<HTMLImageElement | undefined>();
watch(imgRef, (val) => {
  if (val) {
    val.onerror = () => {
      emit('error', new Error(t('failed-to-load-image')));
    };
    val.onload = () => {
      size.value = { width: val.naturalWidth, height: val.naturalHeight };
      scale.value = val.width / val.naturalWidth;
    };
  }
});
function assertAspectRatio(crop: Required<CropOptions>): Required<CropOptions> {
  if (!preserveAspectRatio?.value) return crop;
  const aspectRatio =
    typeof preserveAspectRatio.value === 'number' ? preserveAspectRatio.value : size.value.width / size.value.height;
  const actualRatio = crop.cropwidth / crop.cropheight;
  if (actualRatio > aspectRatio) {
    //cropwidth is too big
    const newWidth = crop.cropheight * aspectRatio;
    const newLeft = crop.cropleft + (crop.cropwidth - newWidth) / 2;
    return {
      ...crop,
      cropwidth: newWidth,
      cropleft: newLeft,
    };
  } else if (actualRatio < aspectRatio) {
    //cropheight is too big
    const newHeight = crop.cropwidth / aspectRatio;
    const newTop = crop.croptop + (crop.cropheight - newHeight) / 2;
    return {
      ...crop,
      cropheight: newHeight,
      croptop: newTop,
    };
  } else {
    return crop;
  }
}
type Quadrant =
  | 'top-left'
  | 'top-middle'
  | 'top-right'
  | 'center-left'
  | 'center-middle'
  | 'center-right'
  | 'bottom-left'
  | 'bottom-middle'
  | 'bottom-right';
function down(e: MouseEvent | TouchEvent, quadrant: Quadrant) {
  function normalizeEvent(e: MouseEvent | TouchEvent) {
    if (e instanceof MouseEvent) {
      return e;
    } else {
      return e.touches[0];
    }
  }
  const normal = normalizeEvent(e);
  const resize = quadrant !== 'center-middle';
  const origin = {
    x: normal.clientX,
    y: normal.clientY,
  };
  const originalCrop = {
    left: crop.value.cropleft ?? 0,
    top: crop.value.croptop ?? 0,
    width: crop.value.cropwidth ?? 0,
    height: crop.value.cropheight ?? 0,
  };
  const move = throttle((e: MouseEvent | TouchEvent) => {
    const normal = normalizeEvent(e);
    const { clientX, clientY } = normal;
    const delta = {
      x: clientX - origin.x,
      y: clientY - origin.y,
    };
    let newCrop = {
      cropleft: originalCrop.left,
      croptop: originalCrop.top,
      cropwidth: originalCrop.width,
      cropheight: originalCrop.height,
    } as Required<CropOptions>;
    if (resize) {
      //resize
      if (quadrant.includes('left')) {
        newCrop.cropleft = originalCrop.left + delta.x / scale.value;
        newCrop.cropwidth = originalCrop.width - delta.x / scale.value;
      } else if (quadrant.includes('right')) {
        newCrop.cropwidth = originalCrop.width + delta.x / scale.value;
      }
      if (quadrant.includes('top')) {
        newCrop.croptop = originalCrop.top + delta.y / scale.value;
        newCrop.cropheight = originalCrop.height - delta.y / scale.value;
      } else if (quadrant.includes('bottom')) {
        newCrop.cropheight = originalCrop.height + delta.y / scale.value;
      }
    } else {
      //move
      newCrop.cropleft = originalCrop.left + delta.x / scale.value;
      newCrop.croptop = originalCrop.top + delta.y / scale.value;
    }
    //contain
    if (newCrop.cropleft < 0) {
      newCrop.cropleft = 0;
    }
    if (newCrop.croptop < 0) {
      newCrop.croptop = 0;
    }
    const minH = minHeight?.value ?? 50 / scale.value;
    if (newCrop.cropheight < minH) {
      newCrop.cropheight = minH;
    }
    const minW = minWidth?.value ?? 50 / scale.value;
    if (newCrop.cropwidth < minW) {
      newCrop.cropwidth = minW;
    }
    if (newCrop.cropleft + newCrop.cropwidth > size.value.width) {
      newCrop.cropleft = Math.max(0, size.value.width - newCrop.cropwidth);
    }
    if (newCrop.croptop + newCrop.cropheight > size.value.height) {
      newCrop.croptop = Math.max(0, size.value.height - newCrop.cropheight);
    }
    if (newCrop.cropwidth > size.value.width) {
      newCrop.cropwidth = size.value.width;
    }
    if (newCrop.cropheight > size.value.height) {
      newCrop.cropheight = size.value.height;
    }
    //aspect ratio
    newCrop = assertAspectRatio(newCrop);
    emit('update:crop', newCrop);
  }, 16);
  function up(e: MouseEvent | TouchEvent) {
    document.removeEventListener('mousemove', move);
    document.removeEventListener('mouseup', up);
    document.removeEventListener('touchmove', move);
    document.removeEventListener('touchend', up);
  }
  document.addEventListener('mousemove', move);
  document.addEventListener('mouseup', up);
  document.addEventListener('touchmove', move);
  document.addEventListener('touchend', up);
  document.addEventListener('touchcancel', up);
}
</script>

<template>
  <div class="b-image-cropper">
    <img ref="imgRef" :src="src" />
    <div class="overlay">
      <div
        class="crop"
        :style="{
          width: (crop.cropwidth ?? 0) * scale + 'px',
          height: (crop.cropheight ?? 0) * scale + 'px',
          top: (crop.croptop ?? 0) * scale + 'px',
          left: (crop.cropleft ?? 0) * scale + 'px',
        }"
      >
        <div
          class="radius"
          :style="{
            borderRadius: radius,
          }"
        />
        <div class="ruleOfThirds">
          <div class="top left" @mousedown="(e) => down(e, 'top-left')" @touchstart="(e) => down(e, 'top-left')">
            <div class="handle" @mousedown="(e) => down(e, 'top-left')" @touchstart="(e) => down(e, 'top-left')" />
          </div>
          <div class="top middle" @mousedown="(e) => down(e, 'top-middle')" @touchstart="(e) => down(e, 'top-middle')">
            <div class="handle" @mousedown="(e) => down(e, 'top-middle')" @touchstart="(e) => down(e, 'top-middle')" />
          </div>
          <div class="top right" @mousedown="(e) => down(e, 'top-right')" @touchstart="(e) => down(e, 'top-right')">
            <div class="handle" @mousedown="(e) => down(e, 'top-right')" @touchstart="(e) => down(e, 'top-right')" />
          </div>
          <div class="center left" @mousedown="(e) => down(e, 'center-left')" @touchstart="(e) => down(e, 'center-left')">
            <div class="handle" @mousedown="(e) => down(e, 'center-left')" @touchstart="(e) => down(e, 'center-left')" />
          </div>
          <div class="center middle">
            <div
              class="center-middle-touch-area"
              @mousedown="(e) => down(e, 'center-middle')"
              @touchstart="(e) => down(e, 'center-middle')"
            />
          </div>
          <div class="center right" @mousedown="(e) => down(e, 'center-right')" @touchstart="(e) => down(e, 'center-right')">
            <div class="handle" @mousedown="(e) => down(e, 'center-right')" @touchstart="(e) => down(e, 'center-right')" />
          </div>
          <div class="bottom left" @mousedown="(e) => down(e, 'bottom-left')" @touchstart="(e) => down(e, 'bottom-left')">
            <div class="handle" @mousedown="(e) => down(e, 'bottom-left')" @touchstart="(e) => down(e, 'bottom-left')" />
          </div>
          <div class="bottom middle" @mousedown="(e) => down(e, 'bottom-middle')" @touchstart="(e) => down(e, 'bottom-middle')">
            <div class="handle" @mousedown="(e) => down(e, 'bottom-middle')" @touchstart="(e) => down(e, 'bottom-middle')" />
          </div>
          <div class="bottom right" @mousedown="(e) => down(e, 'bottom-right')" @touchstart="(e) => down(e, 'bottom-right')">
            <div class="handle" @mousedown="(e) => down(e, 'bottom-right')" @touchstart="(e) => down(e, 'bottom-right')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.b-image-cropper {
  --handle-size: 0.75em;
  position: relative;
  overflow: hidden;
  user-select: none;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    > .crop {
      touch-action: none;
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      > .radius {
        pointer-events: none;
        position: absolute;
        inset: 0;
        box-shadow: 0 0 0 9999px black;
        opacity: 0.6;
      }
      > .ruleOfThirds {
        border: 1px solid white;
        position: absolute;
        inset: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        > * {
          position: relative;
          border-color: white;
          border-style: dashed;
        }

        > * > .handle {
          border-radius: 50%;
          background-color: white;
          width: var(--handle-size);
          height: var(--handle-size);
          position: absolute;
          &::before {
            content: '';
            position: absolute;
            inset: -100%;
            border-radius: 50%;
          }
        }
        > .center {
          border-top-width: 1px;
          border-bottom-width: 1px;
        }
        > .middle {
          border-left-width: 1px;
          border-right-width: 1px;
        }
        > .top.left {
          cursor: nwse-resize;
          > .handle {
            top: calc(var(--handle-size) / -2);
            left: calc(var(--handle-size) / -2);
          }
        }
        > .top.middle {
          cursor: ns-resize;
          > .handle {
            top: calc(var(--handle-size) / -2);
            left: 50%;
            translate: -50%;
          }
        }
        > .top.right {
          cursor: nesw-resize;
          > .handle {
            top: calc(var(--handle-size) / -2);
            right: calc(var(--handle-size) / -2);
          }
        }
        > .center.left {
          cursor: ew-resize;
          > .handle {
            left: calc(var(--handle-size) / -2);
            top: 50%;
            translate: 0 -50%;
          }
        }
        > .center.middle {
          cursor: move;
          position: relative;
          > .center-middle-touch-area {
            position: absolute;
            inset: -50%;
            cursor: move;
            border-radius: 50%;
          }
        }
        > .center.right {
          cursor: ew-resize;
          > .handle {
            right: calc(var(--handle-size) / -2);
            top: 50%;
            translate: 0 -50%;
          }
        }
        > .bottom.left {
          cursor: nesw-resize;
          > .handle {
            bottom: calc(var(--handle-size) / -2);
            left: calc(var(--handle-size) / -2);
          }
        }
        > .bottom.middle {
          cursor: ns-resize;
          > .handle {
            bottom: calc(var(--handle-size) / -2);
            left: 50%;
            translate: -50%;
          }
        }
        > .bottom.right {
          cursor: nwse-resize;
          > .handle {
            bottom: calc(var(--handle-size) / -2);
            right: calc(var(--handle-size) / -2);
          }
        }
        > .center.middle {
          cursor: move;
        }
      }
    }
  }
}
</style>
