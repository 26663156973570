import { BaseController } from './baseController';
import { NotificationSettingsOutput } from '@bluepic/types/src/Auth/notificationSettings.output';
import { NotificationSettingsUpdate } from '@bluepic/types/src/Auth/notificationSettings.update';
import { PaginatedResponseDto } from '@bluepic/types/src/Auth/paginatedResponse.dto';
import { OperationOutput } from '@bluepic/types/src/Auth/operation.output';
import { NotificationOutput } from '@bluepic/types/src/Auth/notification.output';

const baseUrl = import.meta.env.V_NOTIFICATIONS_BASE_URL;

const c = new BaseController(baseUrl);

// GET /settings/{userId}
export async function getSettings(userId?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('settings', userId);

  const response = await fetch(url, {
    headers: {
      ...c.authHeader(jwt, apikey),
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get settings');
  }

  return (await response.json()) as NotificationSettingsOutput;
}

// PATCH /settings/{userId}
export async function updateSettings(data: NotificationSettingsUpdate, userId?: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('settings', userId);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to update settings');
  }

  return (await response.json()) as NotificationSettingsOutput;
}

// GET /notification/{userId}?skip={skip}&take={take}
export async function getNotifications(
  options: {
    userId?: string;
    skip?: number;
    take?: number;
  },
  jwt?: string,
  apikey?: string
) {
  const { userId, skip, take } = options;

  const url = c.getUrl('notification', userId) + '?' + c.query({ skip, take });

  const response = await fetch(url, {
    headers: {
      ...c.authHeader(jwt, apikey),
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get notifications');
  }

  return (await response.json()) as PaginatedResponseDto<NotificationOutput>;
}

// PATCH /notification/mark-as-read
export async function markAsRead(ids: string[], jwt?: string, apikey?: string) {
  const url = c.getUrl('notification/mark-as-read');

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify(ids),
  });

  if (!response.ok) {
    throw new Error('Failed to mark notifications as read');
  }

  return (await response.json()) as OperationOutput;
}

// DELETE /notification?id={id}&id={id2}
export async function deleteNotifications(ids: string[], jwt?: string, apikey?: string) {
  const url = c.getUrl('notification?') + c.query({ id: ids });

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      ...c.authHeader(jwt, apikey),
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete notifications');
  }

  return (await response.json()) as OperationOutput;
}

// POST /notification/broadcast/{spaceId}
export async function broadcastNotification(spaceId: string, message: string, jwt?: string, apikey?: string) {
  const url = c.getUrl('notification/broadcast', spaceId);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...c.authHeader(jwt, apikey),
    },
    body: JSON.stringify({ message }),
  });

  if (!response.ok) {
    throw new Error('Failed to broadcast notification');
  }

  return (await response.json()) as OperationOutput;
}
