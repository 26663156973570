export class BaseController {
  protected baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  getUrl(...path: (string | undefined | null)[]) {
    return this.baseUrl + '/' + path.join('/');
  }
  authHeader(jwt?: string, apikey?: string) {
    const prefix = jwt ? 'bearer' : apikey ? 'apikey' : null;
    if (!prefix) return;
    return {
      Authorization: `${prefix} ${jwt ? jwt : apikey}`,
    };
  }
  query(params: any) {
    if (!params) return '';
    const result: string[] = [];
    for (const k in params) {
      const key = encodeURIComponent(k);
      const rawValue = params[k];
      if (Array.isArray(rawValue)) {
        for (const i of rawValue) {
          if (i === undefined) continue;
          result.push(`${key}=${encodeURIComponent(i)}`);
        }
      } else if (rawValue !== undefined) {
        result.push(`${key}=${encodeURIComponent(rawValue)}`);
      }
    }
    return result.join('&');
  }
}
